<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template #default>
          <tbody>
            <tr>
              <td>
                <v-chip
                  color="red"
                  dark
                  small
                  label
                >
                  Fijado 12:30
                </v-chip>
              </td>
              <td>
                Con fecha fijada
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  label
                  color="yellow"
                >
                  M
                </v-chip>
              </td>
              <td>
                Prioridad
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  label
                >
                  Revisión
                </v-chip>
              </td>
              <td>
                Checklist sin anomalías
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  :color="CHECKLIST.colores.conAnomalias"
                  text-color="white"
                  small
                  label
                >
                  Revisión
                </v-chip>
              </td>
              <td>
                Checklist con anomalías
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  :color="ORDEN_TRABAJO.colores.conAccionesPendientes"
                  small
                  label
                >
                  realizadas/total
                </v-chip>
              </td>
              <td>
                Acciones pendientes
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  :color="ORDEN_TRABAJO.colores.conAccionesTodasRealizadas"
                  small
                  label
                  dark
                >
                  total
                </v-chip>
              </td>
              <td>
                Todas las acciones realizadas
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="info"
                  small
                  label
                  dark
                >
                  Lista para finalizar
                </v-chip>
              </td>
              <td>
                Estado de la orden de trabajo
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="info"
                  small
                  label
                  dark
                >
                  Devolución
                </v-chip>
              </td>
              <td>
                Contiene material pendiente de devolver
              </td>
            </tr>
            <tr>
              <td>
                <v-icon
                  color="red"
                  small
                >
                  {{ $vuetify.icons.values.locked }}
                </v-icon>
              </td>
              <td>Orden de trabajo bloqueada</td>
            </tr>
            <tr>
              <td>
                <v-icon
                  color="red"
                  small
                >
                  {{ $vuetify.icons.values.avisarAntesDeIr }}
                </v-icon>
              </td>
              <td>
                Avisar antes de ir
              </td>
            </tr>
            <tr>
              <td>
                <v-icon
                  small
                >
                  {{ $vuetify.icons.values.calendar }}
                </v-icon>
              </td>
              <td>
                Con fecha prevista
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { CHECKLIST, ORDEN_TRABAJO } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      ORDEN_TRABAJO,
      CHECKLIST
    }
  }
}
</script>
